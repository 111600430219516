// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dungeon-master-js": () => import("./../../../src/pages/dungeon-master.js" /* webpackChunkName: "component---src-pages-dungeon-master-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rune-reader-js": () => import("./../../../src/pages/rune-reader.js" /* webpackChunkName: "component---src-pages-rune-reader-js" */),
  "component---src-pages-storyteller-js": () => import("./../../../src/pages/storyteller.js" /* webpackChunkName: "component---src-pages-storyteller-js" */)
}

